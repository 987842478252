import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import Image from "../components/Image"

import Letters from "../components/Letters"
import styled from "styled-components"
import { LetterSize, Colors, Sizes } from "../components/Theme"

const Header = styled.header`
  grid-area: header;
  position: relative;

  > div {
    position: relative;
    width: 50px;
    margin: 0;
    height: 200px;
  }

  @media (max-width: ${Sizes.tablet}) {
    justify-self: start;

    > div {
      width: 100px;
      height: auto;
    }
  }
`

const First = styled.div`
  position: absolute;
  transform: translate3d(0, -${LetterSize}px, 0);

  @media (max-width: ${Sizes.tablet}) {
    display: inline;
    position: relative
    transform: initial;
  }
`

const Last = styled.div`
  position: absolute;
  transform: translate3d(${LetterSize}px, 0, 0);

  @media (max-width: ${Sizes.tablet}) {
    display: inline;
    position: relative;
    transform: initial;
  }
`

const ImageBox = styled.div`
  grid-area: image;
  width: 200px;

  @media (max-width: ${Sizes.tablet}) {
    width: 100px;
    justify-self: end;
  }
`

const Bio = styled.div`
  grid-area: bio;
  max-width: 440px;
  justify-self: start;

  @media (max-width: ${Sizes.tablet}) {
    justify-self: center;
  }
`

const List = styled.ul`
  list-style: none;
  margin: 0;
  margin-top: 30px;

  li {
    display: inline;
    font-size: 0.9em;
  }

  li + li {
    &:before {
      content: "∙";
    }
  }
`

const P = styled.p`
  margin-bottom: 0.8em;
`

const Link = styled.a`
  color: ${Colors.Link};

  &:hover {
    color: ${Colors.LetterFinal};
  }
`

const Links = styled.footer`
  @media (max-width: ${Sizes.tablet}) {
    text-align: center;
  }
`

const IndexPage = () => (
  <Layout>
    <SEO />

    <Header>
      <div aria-label="CHRIS CAMPBELL">
        <First>
          <Letters>CHRIS</Letters>
        </First>
        <Last>
          <Letters>CAMPBELL</Letters>
        </Last>
      </div>
    </Header>

    <ImageBox>
      <Image />
    </ImageBox>

    <Bio>
      <div>
        <P>Hello, I'm a Software Engineer Lead living in Hobart, Tasmania.</P>

        <P>
          I have a wealth of experience leading high output engineering teams
          and enjoy building and desiging products, inclusive collaboration,
          contributing to open source, fast feedback loops, and solving hard
          problems.
        </P>

        <P>
          Over the past 10+ years I've been working at{" "}
          <Link href="https://99designs.com" target="__blank">
            99designs
          </Link>{" "}
          most recently in the position of Staff Engineer responsible for
          setting the technical direction within the Connection Group - a group
          focused on the nexus of clients, creators, and partners.
        </P>

        <P>
          High rotation buzzwords: AWS, Golang, Elasticsearch, TypeScript,
          GraphQL, React.
        </P>

        <P>For more information on past projects feel free to contact me.</P>
      </div>

      <Links>
        <List>
          <li>
            <Link href="mailto:contact@cc03.me">Email</Link>
          </li>
          <li>
            <Link href="https://github.com/dannymidnight" target="__blank">
              GitHub
            </Link>
          </li>

          <li>
            <Link
              href="https://www.linkedin.com/in/chris-campbell-8202a724/"
              target="__blank"
            >
              LinkedIn
            </Link>
          </li>

          <li>
            <Link href="https://twitter.com/chrcam" target="__blank">
              Twitter
            </Link>
          </li>
        </List>
      </Links>
    </Bio>
  </Layout>
)

export default IndexPage
