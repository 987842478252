import { Colors, LetterSize } from "./Theme"
import { Motion, spring } from "react-motion"
import * as React from "react"
import styled from "styled-components"

interface LetterProps {
  final: boolean
}

const Letter = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: ${LetterSize}px;
  height: ${LetterSize}px;
  font-size: ${LetterSize * 0.7}px;

  font-family: Raleway;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  color: ${(props: LetterProps) =>
    props.final ? Colors.LetterFinal : Colors.Letter};
`

const random = (...items: string[]) =>
  items[Math.floor(Math.random() * items.length)]

interface State {
  key: number
}

interface Props {
  char: string
  children: (props: {
    letter: string
    onMouseOver?: () => void
    isFinal: boolean
  }) => React.ReactElement
}

const RandomLetter = (props: Props) => {
  const [state, setState] = React.useState<State>({ key: Math.random() })
  const reload = () => setState({ key: Math.random() })

  React.useEffect(() => {
    const delay = Math.random() * 15000

    const id = setTimeout(reload, delay)
    return () => clearTimeout(id)
  })

  const { char, children } = props
  const { key } = state
  let letter: string
  let max = 1

  return (
    <Motion key={key} defaultStyle={{ x: 0 }} style={{ x: spring(max) }}>
      {value => {
        if (value.x !== max) {
          if (letter !== char) {
            letter = random(
              " ",
              "A",
              "B",
              "C",
              "D",
              "E",
              "F",
              "G",
              "H",
              "I",
              "J",
              "K",
              "L",
              "M",
              "N",
              "O",
              "P",
              "Q",
              "R",
              "S",
              "T",
              "U",
              "V",
              "W",
              "X",
              "Y",
              "Z"
            )
          }
        } else {
          letter = char
        }

        return children({
          letter,
          onMouseOver: reload,
          isFinal: letter === char,
        })
      }}
    </Motion>
  )
}

const Letters: React.FunctionComponent = ({ children }) => {
  const word = children.toString()
  const chars = word.split("")

  return chars.map((char, idx) => (
    <RandomLetter key={idx} char={char}>
      {({ letter, isFinal }) => <Letter final={isFinal}>{letter}</Letter>}
    </RandomLetter>
  ))
}

// TODO: Pass in character set OR respect the capitalization of the letter.

export const Text: React.FunctionComponent = ({ children }) => {
  const text = children.toString()
  const chars = text.split("")

  return (
    <React.Fragment>
      {chars.map((char, idx) => (
        <RandomLetter key={idx} char={char}>
          {({ letter, onMouseOver, isFinal }) => (
            <span onMouseOver={onMouseOver}>{letter}</span>
          )}
        </RandomLetter>
      ))}
    </React.Fragment>
  )
}

export default Letters
