import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { Sizes } from "./Theme"

export default function Image() {
  const data = useStaticQuery(graphql`
    query {
      chris: file(relativePath: { eq: "chris-campbell.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 200, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  const { fluid } = data.chris.childImageSharp
  return <Img fluid={fluid} fadeIn={false} />
}
